#sobre-nosotros {
    padding: 50px 20px;
    background-color: #f4f4f4;
  }
  
  .quienes-somos {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .quienes-somos h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: black;
  }
  
  .quienes-somos p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .beneficios-section {
    text-align: center;
  }
  
  .beneficios-section h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: black;
  }
  
  .beneficios-grid {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .beneficio-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .beneficio-item.left {
    flex-direction: row;
  }
  
  .beneficio-item.right {
    flex-direction: row-reverse;
  }
  
  .icono {
    font-size: 3rem;
    margin-right: 20px;
  }
  
  .contenido {
    max-width: 500px;
  }
  
  .contenido h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .contenido p {
    font-size: 1.1rem;
    color: #555;
  }
  
  .boton-servicios {
    margin-top: 50px;
  }
  
  .boton-servicios .btn {
    display: inline-block;
    background-color: #007BFF;
    color: white;
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .boton-servicios .btn:hover {
    background-color: #0057b3c2;
  }

  /* Animación de fade-in para los beneficios */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px); /* Aparece desde abajo */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* En su posición final */
  }
}

/* Estado inicial de los items ocultos */
.beneficio-item {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.beneficio-item.visible {
  opacity: 1;
  transform: translateY(0);
}

  
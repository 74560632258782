footer {
  display: flex; /* Distribuir contenido en línea */
  flex-direction: column; /* Centrar el contenido verticalmente en pantallas pequeñas */
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgb(59, 58, 58);
  color: rgb(172, 172, 172);
  width: 100%;
  bottom: 0;
  position: relative;
}

footer p {
  color: aliceblue;
  margin: 5px 0;
}

.correo {
  color: rgb(252, 252, 252);
  text-decoration: none;
  margin-bottom: 10px;
}

.correo:hover {
  text-decoration: underline; /* Agregar subrayado al pasar el ratón */
}

/* Contenedor para íconos de redes sociales */
.social-icons {
  display: flex;
  gap: 15px; /* Espacio entre íconos */
  margin-top: 10px;
}

.social-icon {
  width: 30px; /* Tamaño del ícono */
  height: 30px;
  transition: transform 0.3s ease;
}

/* Efecto hover en todos los íconos sociales */
.social-icon:hover {
  transform: scale(1.2); /* Aumenta el tamaño al pasar el ratón */
  cursor: pointer;
}

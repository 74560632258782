/* Hero */
#hero {
    text-align: center;
    padding: 100px 20px;
    background-color: #ffffff;
    border: 5px solid transparent;
    border-image-slice: 1;
    color: #000000;
    padding-bottom: 30px; /* Ajusta el valor según el espacio deseado */
  }
  
  #hero h1 {
    font-size: 3.5em;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  
  #hero p {
    margin: 20px 0;
    font-size: 1.2em;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.6);
  }
  
  .btn {
    background: linear-gradient(135deg, #00ffff, #ffa3ff); /* Degradado en el botón */
    background-size: 200% 200%;
    animation: gradientBtn 5s ease infinite;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 10px;
    font-size: 1.2em;
    /* box-shadow: 0 0 10px rgba(0, 255, 255, 0.8), 0 0 20px rgba(252, 217, 252, 0.6); /* Reducido para suavidad */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .btn:hover {
    transform: scale(1.05); /* Reducción de escala para suavizar el efecto */
    /* box-shadow: 0 0 10px rgba(0, 255, 255, 0.8), 0 0 30px rgba(247, 124, 247, 0.418); /* Menos intensidad */
  }
  
  /* Servicios */
#services {
    padding: 50px 20px;
    text-align: center;
    background-color: #f9f9f9;
    color: #333;
  }
  
  #services h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  #services ul {
    list-style: none;
    padding: 0;
  }
  
  .service-item {
    font-size: 1.2em;
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Alinea el texto a la izquierda y el ícono a la derecha */
    gap: 10px;
    opacity: 0;
    animation: fadeIn 0.8s ease forwards;
    padding: 15px 20px;
    background-color: #e9ecef;
    border-radius: 10px;
    width: 90%; /* Más ancho */
    max-width: 700px;
  }
  
  .service-text {
    flex: 1; /* Alinea el texto a la izquierda */
  }
  
  .service-icon {
    font-size: 1.5em;
  }
  
  
  .service-item span {
    font-size: 1.5em;
    margin-left: auto; /* Alinea el icono a la derecha */
  }
  
  .appear {
    animation: fadeIn 0.8s ease forwards;
  }
  
  /* Animación de aparición */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Estilos para la sección de Proyectos en Curso */
#proyectos-en-curso {
    padding: 3rem;
    background-color: #f9f9f9;
    margin-top: 10px;
    justify-content: center;
    text-align: center; /* Para centrar el título */
  }

  .titulop{
    margin: 20px;
    text-align: center;
    padding: 10px;
  }

  .descripcionp{
    color: #000000;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px;
  }
  
  .proyectos-lista {
    display: flex;
    justify-content: center; /* Centra los elementos */
    gap: 20px; /* Espacio entre los elementos */
    flex-wrap: wrap; /* Permite el ajuste de elementos en filas */
    margin-top: 20px;
  }
  
  /* Contenedor de la tarjeta */
  .proyecto-card {
    width: 300px; /* Ajusta el ancho de cada tarjeta */
    height: 300px;
    perspective: 1000px;
  }

.proyecto-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.proyecto-card:hover .proyecto-card-inner {
  transform: rotateY(180deg); /* Rotación de 180 grados */
}

/* Lado frontal de la tarjeta */
.proyecto-card-front,
.proyecto-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.proyecto-card-front {
  background: #fff;
}

.proyecto-imagen {
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

/* Lado trasero de la tarjeta */
.proyecto-card-back {
  background-color: #f9f9f9;
  transform: rotateY(180deg); /* Mantenerlo girado inicialmente */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.btn-ver-proyecto {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.btn-ver-proyecto:hover {
  background-color: #0056b3;
}

  @keyframes slideIn {
    from {
      transform: translateY(-20px); /* Iniciar desde arriba */
      opacity: 0; /* Comenzar oculto */
    }
    to {
      transform: translateY(0); /* Terminar en la posición original */
      opacity: 1; /* Finalizar visible */
    }
  }
  
  .animated {
    animation: slideIn 0.5s forwards; /* Duración de la animación */
    opacity: 0; /* Iniciar oculto */
  }
  
  .proyectos-lista .proyecto-card {
    /* Agregar una animación de retraso para cada proyecto */
    animation-delay: calc(0.1s * var(--index));
    --index: 1; /* Valor por defecto */
  }
  
  /* Testimonios */
  #testimonials {
    padding: 50px 20px;
    background-color: #fff;
    text-align: center;
  }
  
  #testimonials h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .testimonial {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background: #f0f0f0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial p {
    font-style: italic;
    color: #555;
  }
  
  .testimonial h4 {
    margin-top: 15px;
    font-weight: bold;
    color: #333;
  }
  
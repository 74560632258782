/* Estilo para el encabezado */
header {
    background-color: #f5f5f5; /* Fondo de color gris claro, puedes cambiarlo a otro color */
    background-size: cover; /* Asegura que la imagen de fondo cubra todo el espacio */
    background-position: center; /* Centra la imagen */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    color: #333; /* Color del texto */
    text-align: center; /* Centra el texto */
    padding: 60px 20px; /* Añade padding para dar espacio alrededor del texto */
}

/* Ajuste del h2 dentro del header */
header h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #000000; /* Cambia el color del título */
    text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.815); /* Sombra de texto para resaltar */
}

/* Ajuste de los párrafos dentro del header */
header p {
    font-size: 1.2em;
    color: #0a0a0a; /* Cambia el color de los párrafos */
}

/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
    header {
        padding: 40px 10px; /* Reduce el padding en dispositivos móviles */
    }

    header h2 {
        font-size: 2em; /* Ajusta el tamaño del texto en móviles */
    }

    header p {
        font-size: 1em;
    }
}


/* Estilo general para la sección de servicios */
.services-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff; /* Fondo blanco */
    color: #000000; /* Texto oscuro para contraste */
}

/* Lista de servicios */
.services-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 600px;
}

/* Estilos para cada servicio */
.service-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Transiciones suaves */
    width: 100%;
    max-width: 800px;
    background-color: #fff; /* Fondo blanco para el recuadro */
    border: 1px solid #c0c0c0c2;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

/* Efecto hover sobre el recuadro */
.service-item:hover {
    transform: scale(1.05); /* Efecto de zoom suave */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Aumenta la sombra en hover */
}

/* Contenedor de texto de cada servicio */
.service-text {
    flex: 1;
    padding: 20px;
    font-size: 1rem;
    color: #000000; /* Color de texto oscuro */
}

/* Estilos para la imagen del servicio */
.service-image {
    width: 150px;
    height: 150px;
    transition: transform 0.2s ease-in-out; /* Transición suave para la imagen */
    object-fit: cover;
}

.descripcion {
    color: #333333; /* Cambia a un color gris oscuro o negro */
}

/* Efecto hover en la imagen */
.service-image:hover {
    transform: scale(1.1); /* Efecto de zoom en la imagen */
}

/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
    .service-item {
        flex-direction: column;
        text-align: center;
    }

    .service-image {
        width: 100%;
        max-height: 200px;
    }

    .service-text {
        padding: 15px;
        font-size: 1.1rem;
    }
}

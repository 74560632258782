.proyecto2-container {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
}

.proyecto2-hero {
    background-color: #ffffff;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    position: relative;
}

.proyecto2-overlay {
    background-color: rgba(107, 107, 107, 0.5);
    padding: 20px;
}

.proyecto2-fade-in {
    opacity: 0;
    animation: fadeIn 1s forwards;
}

.proyecto2-slide-in {
    transform: translateY(20px);
    opacity: 0;
    animation: slideIn 1s forwards;
}

.proyecto2-content {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Titulo-p{
    color: #07b4f8;
    padding-bottom: 10px;
}

h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

ul {
    list-style-type: none;
    padding: 0;
}

.Desp{
    color: black;
}

.check-icon {
    color: #07b4f8;
    margin-right: 8px;
}

/* Animaciones */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

#hero h2 {
    font-size: 3em;
    text-align: center; /* Centrar texto en dispositivos móviles */
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Efecto neón */
  }
  #hero p {
    margin: 15px 0;
    font-size: 1.2em;
    text-align: center;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.6);
  }

  /* Estilo para la sección del formulario */
.seccion-formulario {
    display: flex;
    justify-content: center; /* Centrar el formulario horizontalmente */
    margin-top: 15px;
  }

  .contact-container {
    display: flex; /* Activa Flexbox */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    min-height: 100vh; /* La altura mínima será toda la ventana */
    background-color: rgb(0, 0, 0); /* Fondo del contenedor */
    padding: 20px; /* Espaciado interno opcional */
    box-sizing: border-box;
  }

  /* Estilo del formulario */
  form {
    background-color: rgb(255, 255, 255); /* Fondo blanco */
    border-radius: 15px; /* Bordes redondeados */
    padding: 25px; /* Espaciado interno */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra elegante */
    max-width: 400px; /* Ancho máximo del formulario */
    width: 100%; /* Para que sea responsive */
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  form div {
    margin-bottom: 15px; /* Espaciado entre los campos */
  }
  
  label {
    font-size: 1.2rem; /* Tamaño de fuente */
    color: black;
    display: block;
    margin-bottom: 5px;
    text-align: center;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    box-sizing: border-box;
    /*box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Sombra ligera al seleccionar */
  }

  input:focus {
    outline: none;
    border-color: #007BFF; /* Cambia el borde al hacer foco */
    /*box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Sombra ligera al seleccionar */
  }

  .tel-container {
    display: flex; /* Utiliza Flexbox para alinear los campos */
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    gap: 10px;
}

.tel-container select {
    padding: 10px; /* Espaciado interno */
    border: 1px solid #ccc; /* Borde gris */
    border-radius: 8px 0 0 8px; /* Bordes redondeados en la izquierda */
    font-size: 1rem; /* Tamaño de fuente */
   /* box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Sombra ligera */
}

.tel-container input {
    padding: 10px; /* Espaciado interno */
    border: 1px solid #ccc; /* Borde gris */
    border-radius: 0 8px 8px 0; /* Bordes redondeados en la derecha */
    font-size: 1rem; /* Tamaño de fuente */
    flex: 1; /* Permite que el input ocupe el resto del espacio */
    /*box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Sombra ligera */
}

.tel-container select:focus,
.tel-container input:focus {
    outline: none; /* Eliminar el contorno */
    border-color: #007BFF; /* Cambiar el borde al hacer foco */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Sombra ligera al seleccionar */
}
  
  textarea {
    width: 100%;
    height: 150px; /* Aumentamos la altura */
    resize: vertical; /* Permitir redimensionar solo en vertical */
    padding: 12px; /* Espaciado interno */
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1.1rem; /* Fuente más grande */
    line-height: 1.5; /* Mejor espacio entre líneas */
    box-sizing: border-box;
  }
  
  textarea:focus {
    outline: none;
    border-color: #007BFF; /* Cambia el borde al hacer foco */
    
  }
  
  form div:last-child {
    display: flex; /* Activamos Flexbox */
    justify-content: center; /* Centramos horizontalmente */
    margin-top: 15px; /* Añadimos un poco de separación superior */
  }

  button {
    background: linear-gradient(135deg, #00ffff, #fa7dfa); /* Degradado en el botón */
    background-size: 200% 200%;
    animation: gradientBtn 5s ease infinite;
    color: white;
    padding: 10px 10px;
    text-decoration: none;
    border-radius: 10px;
    font-size: 1em;
    /*box-shadow: 0 0 10px rgba(0, 255, 255, 0.8), 0 0 20px rgb(252, 217, 252);*/
    transition: transform 0.3s ease;
  }
  
  button:hover {
    transform: scale(1.1);
    /* box-shadow: 0 0 20px rgb(0, 255, 255), 0 0 40px rgba(255, 0, 255, 0.466);*/
  }
  
  
  /* Animaciones del fondo */
  @keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
  }
  
  @keyframes gradientBtn {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%
      }
  }
  
  /* Media Query para pantallas pequeñas */
@media (max-width: 768px) {
    .blur-background {
        padding: 20px;
    }

    form {
        padding: 15px;
    }

    label {
        font-size: 0.9rem;
    }

    input, select, textarea {
        font-size: 0.9rem;
    }

    button {
        padding: 8px 12px;
        font-size: 0.85rem;
    }

    .tel-container {
        flex-direction: column; /* Apilar select e input verticalmente */
    }

    .tel-container select, .tel-container input {
        border-radius: 8px; /* Bordes completos */
    }
}
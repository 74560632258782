/* Reseteo básico para asegurar que no haya margen o padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Estilos generales del body */
body {
  font-family: Arial, sans-serif;
  background-color: #ffffff; /* Fondo blanco */
  color: #000000; /* Texto negro */
  margin: 0;
  padding: 0;
}

header {
  background-color: rgb(0, 0, 0); /* Fondo oscuro del encabezado */
  padding: 10px;
  color: #ffffff; /* Texto blanco en el encabezado */
}

#hero {
  text-align: center;
  padding: 100px 20px;
  background-color: #ffffff; /* Fondo blanco del hero */
  border: 5px solid transparent; /* Borde transparente para iniciar */
  border-image-slice: 1; /* Hacer que el degradado rellene todo el borde */
  color: #000000; /* Texto oscuro sobre fondo blanco */
}

#hero h1 {
  font-size: 3.5em;
  color: #000000; /* Texto negro */
  text-shadow: 0 0 10px rgba(0, 0, 255, 0.2); /* Sombra suave azul para efecto moderno */
}

#hero p {
  margin: 20px 0;
  font-size: 1.2em;
  color: #333333; /* Texto gris oscuro para buen contraste */
  text-shadow: none; /* Sin sombra para claridad */
}

.btn {
  background: linear-gradient(135deg, #00ffff, #ffa3ff); /* Degradado en el botón */
  background-size: 200% 200%;
  animation: gradientBtn 10s ease infinite;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 15px;
  font-size: 1.2em;
  /* box-shadow: 0 0 10px rgba(0, 255, 255, 0.8), 0 0 20px rgba(252, 217, 252, 0.6); /* Reducido para suavidad */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.btn:hover {
  transform: scale(1.05); /* Reducción de escala para suavizar el efecto */
  /* box-shadow: 0 0 10px rgba(0, 255, 255, 0.8), 0 0 30px rgba(247, 124, 247, 0.418); /* Menos intensidad */
}



/* Animaciones del fondo */
@keyframes gradientBG {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

@keyframes gradientBtn {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%
    }
}
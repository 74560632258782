/* Estilos del Menú */
.navbar {
    background-color: rgba(0, 0, 0, 0.986); /* Fondo semi-transparente */
    padding: 35px; /* Espaciado alrededor */
    display: flex;
    justify-content: center; /* Centrar elementos en pantallas grandes */
    align-items: center; /* Alinear verticalmente */
}

.nav-list {
    list-style-type: none; /* Eliminar puntos de la lista */
    /*text-align: center;  Centrar el texto */
    display: flex;
}

.nav-item {
    margin: 0 15px; /* Espacio vertical entre los elementos */
}

.nav-item a {
    color: white; /* Color del texto del enlace */
    text-decoration: none; /* Sin subrayado */
    font-size: 20px; /* Tamaño del texto */
    transition: transform 0.3s ease, color 0.3s ease; /* Efecto al pasar el mouse */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Sombra sutil */
}

.nav-item a:hover {
    color: antiquewhite;
    text-decoration: underline; /* Subrayado al pasar el mouse */
    transform: scale(1.2); /* Aumentar el tamaño al pasar el mouse */
}

/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
    .navbar {
        justify-content:space-between; /*Espacio entre el btón y el menú*/
        
    }
    .nav-list {
        display: none; /* Ocultar por defecto en pantallas pequeñas */
        flex-direction: column; /* Cambiar a columna en pantallas pequeñas */
        align-items: center; /*Alinear los elementos en el centro*/
        width: 100%;/*Ocupar todo el ancho*/
        position: absolute; /* Permite que el menú se superponga al contenido */
        top: 50px; /* Ajusta según la altura de tu navbar */
        left: 0; /* Alinear al lado izquierdo */
        background-color: rgba(0, 0, 0, 0.9); /* Fondo para el menú desplegado */
        z-index: 1000; /* Asegura que se muestre sobre otros elementos */
    }

    .nav-list.active {
        display: flex;
    }
    
    .navbar-toggle {
        display: block; /* Mostrar el botón de hamburguesa */
        cursor: pointer;
        font-size: 24px;
        color: white;
    }
}

@media (min-width: 769px) {
    .navbar-toggle {
        display: none; /* Ocultar el botón de hamburguesa en pantallas grandes */
    }
}